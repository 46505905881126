import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBuilding,
  cilCloud,
  cilCloudDownload,
  cilControl,
  cilEducation,
  cilEnvelopeClosed,
  cilFactory,
  cilHome,
  cilMonitor,
  cilSettings,
  cilStorage,
  cilSwapHorizontal,
  cilUser,
  cilUserFollow,
} from '@coreui/icons'

export const _nav = [
  {
    name: 'Home',
    to: '',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    name: 'Agents',
    icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
    permissions: [
      'agent-read',
      // 'robot-read'
    ],
    children: [
      {
        name: 'Chatbot',
        to: '/agents/chatbot',
        icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
        permission: 'agent-chatbot-read',
      },
      {
        name: 'Email',
        to: '/agents/emails',
        icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
        permission: 'agent-email-read',
      },
      {
        name: 'Recruiter',
        to: '/agents/recruiter',
        icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
        permission: 'agent-recruiter-read',
      },
    ],
  },
  {
    name: 'Process',
    icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
    permissions: ['task-read', 'robot-read'],
    children: [
      {
        name: 'Tasks',
        to: '/tasks',
        icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
        permission: 'task-read',
      },
      {
        name: 'Robots',
        to: '/robots',
        icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
        permission: 'robot-read',
      },
    ],
  },
  {
    name: 'Connectors',
    icon: <CIcon icon={cilSwapHorizontal} customClassName="nav-icon" />,
    permissions: ['connector-read'],
    children: [
      {
        name: 'Email',
        to: '/connectors/email',
        icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
        permission: 'connector-email-read',
      },
      // {
      //   name: 'Dropbox',
      //   to: '/connectors/storage/dropbox',
      //   icon: <CIcon icon={cilCloud} customClassName="nav-icon" />,
      //   permission: 'dropbox-connector-read',
      // },
      // {
      //   name: 'Google Drive',
      //   to: '/connectors/storage/google-drive',
      //   icon: <CIcon icon={cilCloud} customClassName="nav-icon" />,
      //   permission: 'connector-google-drive-read',
      // },
      {
        name: 'Google Drive Share',
        to: '/connectors/storage/google-drive-share',
        icon: <CIcon icon={cilCloud} customClassName="nav-icon" />,
        permission: 'connector-google-drive-share-read',
      },
      {
        name: 'OneDrive',
        to: '/connectors/onedrive',
        icon: <CIcon icon={cilCloud} customClassName="nav-icon" />,
        permission: 'connector-one-drive-read',
      },
      {
        name: 'OneDrive Share',
        to: '/connectors/onedrive-share',
        icon: <CIcon icon={cilCloud} customClassName="nav-icon" />,
        permission: 'connector-one-drive-share-read',
      },
      {
        name: 'Server',
        to: '/connectors/server',
        icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
        permission: 'connector-server-read',
      },
      {
        name: 'Database',
        to: '/connectors/database',
        icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
        permission: 'connector-database-read',
      },
      {
        name: 'AI',
        to: '/connectors/ai',
        icon: <CIcon icon={cilEducation} customClassName="nav-icon" />,
        permission: 'connector-ai-read',
      },
      // {
      //   name: 'Storage',
      //   icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
      //   permission: 'connector-storage-read',
      //   children: [
      //   ]
      // },
    ],
  },
  {
    name: 'Administration',
    icon: <CIcon icon={cilMonitor} customClassName="nav-icon" />,
    permissions: ['user-read', 'role-read', 'tenant-read', 'kit-read'],
    children: [
      {
        name: 'Users',
        to: '/users',
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        permission: 'user-read',
      },
      {
        name: 'Roles',
        to: '/roles',
        icon: <CIcon icon={cilUserFollow} customClassName="nav-icon" />,
        permission: 'role-read',
      },
      {
        name: 'Tenants',
        to: '/tenants',
        icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
        permission: 'tenant-read',
      },
      {
        name: 'Kit',
        to: '/kit',
        icon: <CIcon icon={cilCloudDownload} customClassName="nav-icon" />,
        permission: 'kit-read',
      },
    ],
  },
  {
    name: 'System',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    permissions: ['logs-read', 'debug-read'],
    children: [
      {
        name: 'Logs',
        to: '/logs',
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        permission: 'logs-read',
      },
      {
        name: 'Debug',
        to: '/debug',
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        permission: 'debug-read',
      },
    ],
  },
]
