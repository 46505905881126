import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useLanguage } from '../../../components/context/LanguageContext'
import { Button, Card, Col, Row, Table, Tabs, message, Modal } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { ReloadOutlined, DownloadOutlined, OpenAIOutlined, CheckOutlined, CloseOutlined, CloudUploadOutlined, UploadOutlined } from '@ant-design/icons'
import { apiRequest } from '../../../helpers'

const url = 'agents/recruiters/resumes/'
const item = 'Resume'
const items = 'Resumes'

const AgentRecruiterDetails = ({ agent, refreshData }) => {
  const languageContext = useLanguage()
  const [running, setRunning] = useState(false)
  const [canUpload, setCanUpload] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [resumes, setResumes] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({})
  const [uploadMessages, setUploadMessages] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false)

  const containerRef = useRef(null)
  const fileInputRef = useRef(null)

  const fetchResumes = useCallback(async () => {
    // fetch agent storage details, if storageId is set
    if (agent.storageId) {
      const result = await apiRequest(
        process.env.REACT_APP_API + `connector/storage/${agent.storageId}`,
        'GET'
      )
      if (!result.ok) {
        message.error('Failed to fetch storage details')
        return
      }
      agent.storage = result.data
      setCanUpload(agent.storage?.canUpload)
      setCanDelete(agent.storage?.canDelete)
    }
    // fetch resumes list
    const result = await apiRequest(
      process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes`,
      'GET'
    )
    if (!result.ok) {
      message.error('Failed to fetch resumes')
      return
    }
    setResumes(result.data.map((item, index) => ({ ...item, details: JSON.parse(item.details), key: index })))
  }, [agent])

  useEffect(() => {
    fetchResumes()
  }, [fetchResumes])

  const run = async () => {
    setRunning(true)
    try {
      await apiRequest(
        process.env.REACT_APP_API + `agents/recruiters/${agent.key}/execute`,
        'POST',
        { debug: true }
      )
      await fetchResumes()
    } catch (error) {
      message.error('Failed to execute agent')
    }
    setRunning(false)
  }

  const onResumeDownload = (resume) => {
    window.open(process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes/${resume.id}/download`, '_blank')
  }

  const onResumeProcess = async (resume) => {
    setRunning(true)
    try {
      await apiRequest(
        process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes/${resume.id}/process`,
        'POST',
        { debug: true }
      )
      await fetchResumes()
    } catch (error) {
      message.error('Failed to process resume')
    }
    setRunning(false)
  }

  const sortString = (a, b) => {
    if (!a && !b) return 0
    if (a && !b) return 1
    if (!a && b) return -1
    return a.localeCompare(b)
  }

  const columns = [
    {
      title: 'Filename',
      dataIndex: 'filename',
      key: 'filename',
      sorter: (a, b) => sortString(a.filename, b.filename),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const statusMap = {
          0: 'pending',
          1: 'processing',
          2: 'processed',
          3: 'error',
        }
        return statusMap[status] || ''
      },
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: 'Match',
      dataIndex: 'match',
      key: 'match',
      render: (match, record) => record.status === 2 ? (match ? <CheckOutlined /> : <CloseOutlined />) : '',
      sorter: (a, b) => (a.match === b.match ? 0 : a.match ? -1 : 1),
    },
    {
      title: 'Date',
      dataIndex: ['createdAt'],
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'First Name',
      dataIndex: ['details', 'firstName'],
      key: 'firstName',
      sorter: (a, b) => sortString(a.details?.firstName, b.details?.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: ['details', 'lastName'],
      key: 'lastName',
      sorter: (a, b) => sortString(a.details?.lastName, b.details?.lastName),
    },
    {
      title: 'Gender',
      dataIndex: ['details', 'gender'],
      key: 'gender',
      sorter: (a, b) => sortString(a.details?.gender, b.details?.gender),
    },
    {
      title: 'Exp Yrs',
      dataIndex: ['details', 'yearsOfExperience'],
      key: 'yearsOfExperience',
      sorter: (a, b) => (a.details?.yearsOfExperience || 0) - (b.details?.yearsOfExperience || 0),
    },
    // {
    //   title: 'Age',
    //   dataIndex: ['details', 'birthDate'],
    //   key: 'birthDate',
    //   render: (birthDate, record) => {
    //     const birthYear = new Date(record?.details?.birthDate).getFullYear()
    //     const currentYear = new Date().getFullYear()
    //     return currentYear - birthYear
    //   },
    // },
    {
      title: 'Native Lang',
      dataIndex: ['details', 'nativeLanguage'],
      key: 'nativeLanguage',
      sorter: (a, b) => sortString(a.details?.nativeLanguage, b.details?.nativeLanguage),
    },
    {
      title: 'Score',
      dataIndex: ['details', 'matchingPercentage'],
      key: 'matchingPercentage',
      render: (matchingPercentage) => matchingPercentage ? `${matchingPercentage}%` : '',
      sorter: (a, b) => (a.details?.matchingPercentage || 0) - (b.details?.matchingPercentage || 0),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Button
            type="text"
            icon={<DownloadOutlined />}
            onClick={() => onResumeDownload(record)}
          />
          {record.status !== 2 && (
            <Button
              type="text"
              icon={<OpenAIOutlined />}
              onClick={() => onResumeProcess(record)}
              disabled={running}
            />
          )}
        </div>
      ),
    },
  ]

  const uploadFile = (file) => {
    const formData = new FormData()
    formData.append('resumes', file)

    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.round((e.loaded / e.total) * 100)
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: percentComplete,
        }))
      }
    })

    xhr.onload = async () => {
      if (xhr.status === 200 || xhr.status === 201) {
        setUploadMessages((prevMessages) => [...prevMessages, `${file.name} uploaded successfully.`])
        message.success(`${file.name} uploaded successfully.`)
        await fetchResumes()
      } else {
        setUploadMessages((prevMessages) => [...prevMessages, `Error uploading ${file.name}.`])
        message.error(`Error uploading ${file.name}.`)
      }
      setUploadProgress((prevProgress) => {
        const newProgress = { ...prevProgress }
        delete newProgress[file.name]
        return newProgress
      })
    }

    xhr.onerror = () => {
      setUploadMessages((prevMessages) => [...prevMessages, `Upload failed for ${file.name}.`])
      message.error(`Upload failed for ${file.name}.`)
      setUploadProgress((prevProgress) => {
        const newProgress = { ...prevProgress }
        delete newProgress[file.name]
        return newProgress
      })
    }

    xhr.open('POST', process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes/new`)
    xhr.setRequestHeader('recruiter-id', agent.id)
    xhr.setRequestHeader('tenant-id', agent.tenantId)
    xhr.withCredentials = true;
    xhr.send(formData)
  }

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files)
    files.forEach(uploadFile)
  }

  const handleDragEnter = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!containerRef.current.contains(e.relatedTarget)) {
      setIsDragging(false)
    }
  }, [])

  const handleDragOver = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)

    const files = Array.from(e.dataTransfer.files)
    files.forEach(uploadFile)
  }, [agent])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const deleteResumes = async () => {
    if (hasSelected) {
      const selectedResumes = resumes.filter((resume) => selectedRowKeys.includes(resume.key)).map((resume) => resume.id)
      try {
        await apiRequest(
          process.env.REACT_APP_API + `agents/recruiters/${agent.key}/resumes/delete`,
          'POST',
          { ids: selectedResumes }
        )
        await fetchResumes()
        setSelectedRowKeys([])
      } catch (error) {
        message.error('Failed to delete resumes')
      }
    }
    setDeleteModal(false)
  }

  return (
    <Tabs className="mt-3" defaultActiveKey="2" type="card">
      <Tabs.TabPane tab={languageContext.t('Resumes')} key="2">
        <Content className='p-3'>
          {showDeleteModal && (
            <Modal
              title={`${languageContext.t('Delete')} ${selectedRowKeys.length} ${languageContext.t('resumes')}`}
              open={showDeleteModal}
              onCancel={() => setDeleteModal(false)}
              onOk={() => deleteResumes()}
              className="dyn-form-modal"
            >
              <p>{languageContext.t('DeleteMessage')}</p>
            </Modal>
          )}
          <Row>
            <Col sm={12}>
              <Button className="dyn-btn-primary" onClick={run} disabled={running}>
                {languageContext.t(running ? 'Retrieving' : 'Retrieve')}{' '}{languageContext.t('resumes')}{' '}
                {languageContext.t('now')}
              </Button>
              {running && <span className="ml-2">
                <ReloadOutlined spin />
              </span>}
              {canDelete && hasSelected && <>
                <Button className="dyn-btn-secondary ml-2 mr-2" onClick={() => setDeleteModal(true)}>
                  {languageContext.t('Delete')}{' '}{selectedRowKeys.length}{' '}{languageContext.t('resumes')}
                </Button>
              </>}
            </Col>
            <Col sm={12} style={{ textAlign: 'right' }}>
              {canUpload && (
                <>
                  <Button type="text" icon={<UploadOutlined />} onClick={() => fileInputRef.current.click()} className="mr-3" />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                </>
              )}
              <Button type="text" icon={<ReloadOutlined />} onClick={fetchResumes} />
            </Col>

            {canUpload && Object.keys(uploadProgress).length > 0 && (
              <div style={{ marginTop: '20px' }}>
                <h3>Upload Progress</h3>
                {Object.entries(uploadProgress).map(([fileName, progress]) => (
                  <div key={fileName}>
                    <span>{fileName}: {progress}%</span>
                    <div style={{ width: '100%', backgroundColor: '#f0f0f0', marginTop: '5px' }}>
                      <div style={{ width: `${progress}% `, backgroundColor: '#1890ff', height: '5px' }} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Row>

          <div
            ref={containerRef}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{ position: 'relative' }}
          >
            <Table
              dataSource={resumes}
              columns={columns}
              className="dyn-table"
              rowSelection={canDelete && rowSelection}
              expandable={{
                expandedRowRender: record => (
                  <Card>
                    <table style={{ margin: 0 }}>
                      <tbody>
                        {Object.entries(record.details).map(([keyName, keyValue]) => (
                          <tr key={keyName}>
                            <td><b>{keyName}</b></td>
                            <td>
                              {typeof keyValue === 'string' && (keyValue.startsWith('https://') || keyValue.startsWith('http://')) ? (
                                <a href={keyValue} target="_blank" rel="noopener noreferrer">{keyValue}</a>
                              ) : (
                                String(keyValue)
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Card>
                ),
                rowExpandable: record => record.status === 2 && record.details,
              }}
            />
            {isDragging && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}
              ><div style={{ textAlign: 'center', color: 'white' }}>
                  <CloudUploadOutlined style={{ fontSize: '48px' }} />
                  <h2>Drop files here to upload</h2>
                </div>
              </div>
            )}
          </div>
        </Content>
      </Tabs.TabPane>
    </Tabs>
  )
}

export default AgentRecruiterDetails