import React, { useEffect, useState } from 'react'
import TableContent from '../../../components/TableContent'
import AgentRecruiterDetails from './RecruiterDetails'

const url = 'agents/recruiters'
const item = 'Recruiter'
const items = 'Recruiters'

const AgentRecruiter = () => {
  const [agent, setAgent] = useState(null)
  const [refreshData, setRefreshData] = useState(null)

  const onAgentSelected = (agent) => {
    setAgent(agent)
  }

  return (
    <>
      <TableContent
        url={url}
        type={'agent-recruiter'}
        item={item}
        items={items}
        onRowSelect={onAgentSelected}
        refreshData={refreshData}
      />
      {agent && <AgentRecruiterDetails agent={agent} refreshData={setRefreshData} />}
    </>
  )
}

export default AgentRecruiter
