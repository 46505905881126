import React from 'react'
import RestrictPage from './views/Restrict'
import Kit from './views/administration/Kit'
import ConnectorEmail from './views/connectors/Email'
import ConnectorDatabase from './views/connectors/Database'
import ConnectorAI from './views/connectors/AI'
import AgentEmail from './views/agents/email/Email'
import AgentChatbot from './views/agents/chatbot/Chatbot'
import Role from './views/administration/Role'
import AgentRecruiter from './views/agents/recruiter/Recruiter'
import Log from './views/system/Logs'
import ConnectorOneDrive from './views/connectors/OneDrive'
import ConnectorOneDriveShare from './views/connectors/OneDriveShare'
import ConnectorGoogleDriveShare from './views/connectors/GoogleDriveShare'
import ConnectorServer from './views/connectors/Server'

const Home = React.lazy(() => import('./views/Home'))
const User = React.lazy(() => import('./views/administration/User'))
const Robot = React.lazy(() => import('./views/process/Robot'))
const Task = React.lazy(() => import('./views/process/Task'))
const Result = React.lazy(() => import('./views/Result'))
const Tenant = React.lazy(() => import('./views/administration/Tenant'))

const routes = [
  {
    path: '',
    exact: true,
    name: 'Home',
    element: Home,
    permission: 'authenticated',
  },
  {
    path: '/tenants',
    exact: true,
    name: 'Tenants',
    element: Tenant,
    permission: 'tenant-read',
  },
  {
    path: '/users',
    exact: true,
    name: 'Users',
    element: User,
    permission: 'user-read',
  },
  {
    path: '/roles',
    exact: true,
    name: 'Roles',
    element: Role,
    permission: 'role-read',
  },
  {
    path: '/robots',
    exact: true,
    name: 'Robots',
    element: Robot,
    permission: 'robot-read',
  },
  {
    path: '/tasks',
    exact: true,
    name: 'Tasks',
    element: Task,
    permission: 'task-read',
  },
  {
    path: '/results/:id',
    exact: true,
    name: 'Results',
    element: Result,
    permission: 'result-read',
  },
  {
    path: '/restriction',
    exact: true,
    name: 'Restriction',
    element: RestrictPage,
  },
  {
    path: '/kit',
    exact: true,
    name: 'Downloads',
    element: Kit,
    permission: 'kit-read',
  },
  {
    path: '/connectors/email',
    exact: true,
    name: 'EmailConnectors',
    element: ConnectorEmail,
    permission: 'connector-read',
  },
  {
    path: '/connectors/database',
    exact: true,
    name: 'DatabaseConnectors',
    element: ConnectorDatabase,
    permission: 'connector-read',
  },
  {
    path: '/connectors/ai',
    exact: true,
    name: 'AIConnectors',
    element: ConnectorAI,
    permission: 'connector-read',
  },
  {
    path: '/connectors/onedrive',
    exact: true,
    name: 'OneDriveConnectors',
    element: ConnectorOneDrive,
  },
  {
    path: '/connectors/onedrive-share',
    exact: true,
    name: 'OneDriveShareConnectors',
    element: ConnectorOneDriveShare,
  },
  {
    path: '/connectors/storage/google-drive-share',
    exact: true,
    name: 'GoogleDriveShareConnectors',
    element: ConnectorGoogleDriveShare,
  },
  {
    path: '/connectors/server',
    exact: true,
    name: 'ServerConnectors',
    element: ConnectorServer,
  },
  {
    path: '/agents/emails',
    exact: true,
    name: 'EmailAgents',
    element: AgentEmail,
    permission: 'agent-read',
  },
  {
    path: '/agents/chatbot',
    exact: true,
    name: 'ChatbotAgents',
    element: AgentChatbot,
    permission: 'agent-read',
  },
  {
    path: '/agents/recruiter',
    exact: true,
    name: 'RecruiterAgents',
    element: AgentRecruiter,
    permission: 'agent-read',
  },
  {
    path: '/logs',
    exact: true,
    name: 'Log',
    element: Log,
    permission: 'log-read',
  },
  {
    path: '/terms',
    exact: true,
    name: 'Terms',
    element: () => <div>Terms</div>,
  },
  {
    path: '/privacy',
    exact: true,
    name: 'Privacy',
    element: () => <div>Privacy</div>,
  },
]

export default routes
