import React from 'react'
import TableContent from '../../components/TableContent'
const url = 'connector/server'
const item = 'Server'
const items = 'Server'

const ConnectorServer = () => {
  return (
    <TableContent
      url={url}
      type={'connector-server'}
      item={item}
      items={items}
    />
  )
}
export default ConnectorServer
